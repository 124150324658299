@use '../general' as g;

.parallax {
  height: 100vh;
  padding-top: 1em;
  background-color: var(--parallax-colour);
  background-image: url(../imgs/parallax-small.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  //A fixed background attachment only works on non-touch devices
  //and keeping it turned on means that it just shows a corner of the image
  @media (hover: hover) {
    background-attachment: fixed;
  }

  @include g.breakpoint-up(medium) {
    background-image: url(../imgs/parallax-medium.jpg);
  }

  @include g.breakpoint-up(medium-large) {
    background-image: url(../imgs/parallax-medium-large.jpg);
  }

  @include g.breakpoint-up(large) {
    background-image: url(../imgs/parallax-big.jpg);
  }
}
