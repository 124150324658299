@use '../general' as g;

.header {
  height: 100vh;
  background-color: var(--secondary-colour);
  background-image: url(../imgs/header-small.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  //background-attachment: fixed;

  @include g.breakpoint-up(medium) {
    background-image: url(../imgs/header-medium.jpg);
  }

  @include g.breakpoint-up(medium-large) {
    background-image: url(../imgs/header-medium-large.jpg);
  }

  @include g.breakpoint-up(large) {
    background-image: url(../imgs/header-big.jpg);
  }
  .skip {
    position: absolute;
    top: 0;
    left: 50%;
    text-align: center;
    transform: translateX(-50%) translateY(-100%);
    transition: translate 1000ms var(--easing);
    z-index: 10;

    @include g.breakpoint-up(large) {
      top: unset;
      left: 0.1em;
      transform: translateX(0) translateY(-1000%);
    }
  }

  .skip:focus {
    transform: translateX(-50%) translateY(0.1em);

    @include g.breakpoint-up(large) {
      transform: translateX(0) translateY(0);
    }
  }

  a {
    color: var(--alternate-colour);
  }

  &.short {
    height: auto;
    background-position: top;
  }

  &__lightdark {
    position: absolute;
    top: 0.5em;
    //right: 1em;
    left: calc(100vw - 3em);
    color: var(--alternate-colour);
    background-color: transparent;
    border: 0;
    z-index: 2;
    cursor: pointer;

    &:hover,
    &:focus {
      color: var(--primary-colour);
    }
  }

  &__title {
    h1 {
      display: grid;
      place-content: center;
      padding: 0.25em;
      font-size: 1.5rem;
      font-weight: 700;
      color: var(--alternate-colour);
      text-align: center;
      text-transform: uppercase;
      line-height: 1;

      @include g.breakpoint-up(medium) {
        font-size: 2rem;
      }
    }
  }

  .las {
    font-size: 1.5rem;
    transition: color 250ms ease-in-out;
  }
}
