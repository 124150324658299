$breakpoints-up: (
  //376px
  'small': '23.5em',
  //448px
  'medium': '28em',
  //640px
  'medium-large': '40em',
  //944px
  'large': '59em'
);

@mixin breakpoint-up($size) {
  @media (min-width: map-get($breakpoints-up, $size)) {
    @content;
  }
}
