@use '../general' as g;

.pageVideos {
  //margin-top: 3em;
  display: grid;
  grid-template-rows: repeat(2, auto);
  justify-items: center;
  gap: 1em;

  @supports not (gap: 2em) {
    &__card:not(:last-of-type) {
      margin-bottom: 2em;
    }
  }

  @include g.breakpoint-up(medium-large) {
    grid-template-columns: repeat(2, auto);
    grid-template-rows: auto;

    @supports not (gap: 2em) {
      &__card:not(:last-of-type) {
        margin-right: 2em;
      }
    }
  }

  &__container {
    position: relative;
    display: inline-block;
    max-width: 90%;

    &:hover .pageVideos__control,
    &:focus .pageVideos__control {
      opacity: 1 !important;
    }

    @include g.breakpoint-up(medium-large) {
      &:last-of-type {
        transform: translateY(-2em);
      }
    }
  }

  &__video {
    //These two get rid of space below video
    display: block;
    font-size: 0;
    width: 100%;
    cursor: pointer;

    &:first-of-type {
      margin-bottom: 2em;
    }
  }

  &__control {
    --button-width: 2em;
    --button-height: 2em;
    position: absolute;
    width: var(--button-width);
    height: var(--button-height);
    top: calc(50% - calc(var(--button-width) / 2));
    left: calc(50% - calc(var(--button-height) / 2));
    display: grid;
    place-content: center;
    border-radius: 50%;
    border: 2px solid var(--secondary-colour);
    background-color: var(--alternate-colour);
    color: var(--secondary-colour);
    cursor: pointer;
    opacity: 0.75;

    &:focus,
    &:hover {
      background-color: var(--secondary-colour);
      color: var(--alternate-colour);
    }
  }
}
