@use '../general' as g;

.pageIntro {
  &__imagetext {
    @include g.breakpoint-up(medium-large) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2em;
    }
  }

  @supports not (gap: 2em) {
    &__card:not(:last-of-type) {
      margin-right: 2em;
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 0.5em;

    @include g.breakpoint-up(medium-large) {
      margin-bottom: 1em;
    }
  }

  &__image {
    margin-bottom: 1em;

    @include g.breakpoint-up(medium-large) {
      margin-bottom: 0;
    }
  }

  &__intro {
    p {
      margin-bottom: 1em;
    }

    h3 {
      margin-bottom: 0.5em;
    }

    h3:last-of-type {
      margin-top: 1.5em;
    }
  }
}
